body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #c396eb, #a874d5);
}

.info, .projects, .resume, .contact, .footer {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
  margin: 50px;
  background-color: rgba(255, 255, 255, 0.5); /* Optional: to make the content stand out */
  border-radius: 10px; /* Optional: to add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: to add a shadow */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #2e2b5f; /* Dark purple background */
  color: black;
}

a {
  color: #706abe; /* Light purple link color */
  text-decoration: none;
}

a:hover {
  color: #a29bfe; /* Lighter color on hover */
}

.App {
  text-align: center;
  position: relative;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bonbon-regular {
  font-family: "Bonbon", cursive;
  font-weight: 400;
  font-style: normal;
}

.hachi-maru-pop-regular {
  font-family: "Hachi Maru Pop", serif;
  font-weight: 400;
  font-style: normal;
}

.handlee-regular {
  font-family: "Handlee", cursive;
  font-weight: 400;
  font-style: normal;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.project img {
  width: 50%;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.project img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.project-description {
  margin: 20px 0;
  text-align: center;
}

.project-button {
  padding: 10px 20px;
  background-color: #a16ac8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.project-button:hover {
  background-color: #be8cd7;
}

.show-more-button {
  padding: 10px 20px;
  background-color: #a16ac8; /* Light purple background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.show-more-button:hover {
  background-color: #be8cd7; /* Lighter color on hover */
}

.work-experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.experience {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;
}

.education img {
  width: 100px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
}

.experience img {
  width: 100px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
}

.experience-content, .education-content {
  flex: 1;
}

.company-name, .position, .school-name {
  text-align: left;
  margin: 0;
}

.dates-worked {
  text-align: right;
  margin: 0;
}

.description {
  text-align: left;
  margin-top: 10px;
}

.skillsWork {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.skillsWork .skill-button {
  padding: 5px 10px;
  margin: 5px;
  background-color: transparent;
  color: #a16ac8;
  border: 2px solid #a16ac8;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.skillsWork .skill-button:hover {
  background-color: #a16ac8;
  color: white;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skills-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.skills-icons .fa-icon,
.skills-icons .devicon-icon {
  margin: 10px;
  position: relative;
}

.skills-icons .fa-icon:hover::after,
.skills-icons .devicon-icon:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.3s;
}

.skills-icons .fa-icon::after,
.skills-icons .devicon-icon::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  content: attr(data-tooltip);
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.skill-button {
  padding: 5px 10px;
  margin: 5px;
  background-color: #ff7e5f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.skill-button:hover {
  background-color: #feb47b;
}

.skills-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.skills-icons .fa-icon {
  margin: 10px;
}

.education {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Top bar styles */
.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.top-bar a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.top-bar a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Mobile menu styles */
.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1001;
}

.top-bar.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.top-bar.vertical a {
  padding: 10px;
  width: 100%;
  text-align: left;
}

.section {
  padding-top: 20px; /* Adjust this value to prevent content from being hidden behind the fixed top bar */
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6c5ce7; /* Purple background */
  height: 100vh; /* Full height of the viewport */
  color: white; /* Text color */
  text-align: center;
}

.title h1 {
  font-size: 3rem;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid #ffffff; /* Cursor effect */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  /* pause between rounds of the animation */
  animation-delay: 1s;
}

.title-container {
  display: inline-block; /* Make the container fit the content */
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: #ffffff; }
}

.arrow {
  margin-top: 20px;
  font-size: 2rem;
  cursor: pointer;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.dev-image {
  width: 250px;
  height: 250px;
  border-radius: 10%;
  border: 5px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
}

.info {
  display: flex;
  align-items: center;
  margin: 50px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info p {
  margin: 0;
  padding: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .top-bar {
    display: none;
  }

  .top-bar.vertical {
    display: flex;
  }

  .info {
    flex-direction: column;
    text-align: center;
  }

  .dev-image {
    margin-right: 0;
    margin-bottom: 20px;
    width: 50%;
    height: auto;
  }

  .title h1 {
    font-size: 2rem;
  }

  .work-experience .dates-worked {
    text-align: left; /* Left align dates worked on mobile devices */
  }

  .work-experience .dates-worked {
    text-align: left; /* Left align dates worked on mobile devices */
  }

  .dates-worked {
    text-align: left; /* Left align dates worked on mobile devices */
  }

  .work-experience img {
    width: 50px;
    height: auto;
  }

  .education img {
    width: 50px;
    height: auto;
  }

  .other-experience img {
    width: 50px;
    height: auto;
  }
  
}

.devicon-devicon-plain {
  max-width: 3em;
}

/* if you want to change the original color */
.devicon-devicon-plain path {
  fill: black;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100vh; /* Full height of the viewport */
  color: white; /* Text color */
  text-align: center;
}

.arrow {
  margin-top: 20px;
  font-size: 2rem;
  cursor: pointer;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

#projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the project cards horizontally */
  gap: 10px;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  flex: 1 1 33%; /* Make the width of the project cards be 33% of the parent container */
  box-sizing: border-box;
}

.project:hover {
  transform: scale(1.05);
}

.project img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  width: 30%;
}

.project-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .project {
    flex: 1 1 100%; /* One card per row on smaller screens */
  }
}